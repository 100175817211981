<template>
  <div>
    <div>
      <banner></banner>
      <!-- 公司简介 -->
      <div class="introduction content">
        <Title dsc="COMPANY INTRODUCTION" title="公司介绍"></Title>
        <div class="con">
          <div class="titles">
            <h1>中云汇（成都）科技集团有限公司</h1>
            <p>“厕” 重环保， “便” 通自然</p>
            <span>
              中云汇（成都）科技集团有限公司是一家专注于ISO
              30500国际标准无下水道卫生厕所（5G智慧碳中和公厕）产品的研发、设计、制造、销售、服务、运营与维护于一体的省级创新型中小企业和国家级高新技术企业，以“新思路、新基建、新产业”的“三新思维”来引领传统水冲公厕转型升级。</span
            >
          </div>
          <router-link class="btns" to="/center">了解更多</router-link>
        </div>
      </div>
      <!-- 5G智慧轻松驿站 -->
      <div id="public" class="public con content">
        <particles></particles>
      </div>
    </div>
    <div class="advantage con content">
      <Title dsc="OUR ADVANTAGE" title="我们的优势"></Title>
      <!--      <div class="newspaper">-->
      <!--        <journalism></journalism>-->
      <!--        <div class="xia">城市之光、科技之光、文明之光、绿色之光、时尚之光！&#45;&#45;媒体报道</div>-->
      <!--      </div>-->
      <div class="list">
        <ul>
          <li>
            <img alt="" src="../assets/image/icon-1.png" />
            <div>支持</div>
            <p>响应国家号召，开展“厕所革新”这场改革，政</p>
            <p>府大力支持企业创新，提供资源帮助</p>
          </li>
          <li>
            <img alt="" src="../assets/image/icon-2.png" />
            <div>媒体报道</div>
            <p>CCTV央视媒体报道，以及多家电视媒</p>
            <p>体和网络媒体进行了采访报道</p>
          </li>
          <li>
            <img alt="" src="../assets/image/icon-3.png" />
            <div>智能管理</div>
            <p>智能管理 SaaS云平台,利用大数据</p>
            <p>云平台进行操作管理，互联互动</p>
          </li>
          <li class="mt-30">
            <img alt="" src="../assets/image/icon-4.png" />
            <div>创新产品</div>
            <p>运用微生物基因工程技术，强调生物净化，资源</p>
            <p>循环再利用，它打破了传统水冲厕所</p>
          </li>
          <li class="mt-30">
            <img alt="" src="../assets/image/icon-5.png" />
            <div>生物净化</div>
            <p>利用微生物的作用和酶活性加速有机物的生物</p>
            <p>降解和转化，改善土壤结构、提高土壤肥力</p>
          </li>
          <li class="mt-30">
            <img alt="" src="../assets/image/icon-6.png" />
            <div>循环利用</div>
            <p>智能无水生态厕所具有“有机肥生产工厂”的美</p>
            <p>誉，积极推动生态发展</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="saas">
      <div class="hed">SAAS云平台系统，提供大数据解决方案</div>
      <div class="dsc">
        SAAS CLOUD PLATFORM SYSTEM, PROVIDING BIG DATA SOLUTIONS
      </div>
      <ul class="con">
        <li>
          <img alt="" src="../assets/image/saas1.png" />
        </li>
        <li>
          <img alt="" src="../assets/image/saas2.png" />
        </li>
        <li>
          <img alt="" src="../assets/image/saas3.png" />
        </li>
        <li>
          <img alt="" src="../assets/image/saas4.png" />
        </li>
      </ul>
    </div>
    <!-- 环保 -->
    <div v-if="this.deviceType === 'pc'" class="environmental">
      <div class="contents">
        <Title
          dsc="INTERNET INTELLIGENT TERMINAL EXPERIENCE SERVICE"
          style="margin-top: 10px"
          title="互联网智能终端体验服务"
        ></Title>
        <div class="env-icon">
          <div v-show="theFirstRow" class="env_icon-1">
            <img src="../assets/image/app.png" />
            <p>维保APP应用程序</p>
          </div>
          <div v-show="theFirstRow" class="env_icon-2">
            <img src="../assets/image/xx.png" />
            <p>信息管理平台</p>
          </div>
          <div v-show="theFirstRow" class="env_icon-3">
            <img src="../assets/image/dashuju.png" />
            <p>大数据监控平台</p>
          </div>
          <div v-show="theFirstRow" class="env_icon-4">
            <img src="../assets/image/xcx.png" />
            <p>微信小程序</p>
          </div>
          <div v-show="theFirstRow" class="env_icon-5">
            <img src="../assets/image/rennian.png" />
            <p>如厕体验评价系统</p>
          </div>
          <div v-show="theFirstRow" class="env_icon-6">
            <img src="../assets/image/gg.png" />
            <p>广告信息发布系统</p>
          </div>
        </div>
        <div class="env-img">
          <img src="../assets/image/env2.png" />
        </div>
      </div>
    </div>
    <!-- 环保 --mobile -->
    <div v-if="this.deviceType === 'mobile'" class="environmental-mobile">
      <div class="contents">
        <Title
          dsc="INTERNET INTELLIGENT TERMINAL EXPERIENCE SERVICE"
          title="互联网智能终端体验服务"
        ></Title>
        <div class="env-img">
          <img src="../assets/image/env2.png" />
        </div>
        <div class="env-icon">
          <div v-show="theFirstRow" class="env_icon-1">
            <img src="../assets/image/app.png" />
            <p>维保APP应用程序</p>
          </div>
          <div v-show="theFirstRow" class="env_icon-1">
            <img src="../assets/image/xx.png" />
            <p>信息管理平台</p>
          </div>
          <div v-show="theFirstRow" class="env_icon-1">
            <img src="../assets/image/dashuju.png" />
            <p>大数据监控平台</p>
          </div>
          <div v-show="theFirstRow" class="env_icon-1">
            <img src="../assets/image/xcx.png" />
            <p>微信小程序</p>
          </div>
          <div v-show="theFirstRow" class="env_icon-1">
            <img src="../assets/image/rennian.png" />
            <p>如厕体验评价系统</p>
          </div>
          <div v-show="theFirstRow" class="env_icon-1">
            <img src="../assets/image/gg.png" />
            <p>广告信息发布系统</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 企业广告   -->
    <div class="env_bot content">
      <div class="con content">
        <div class="left">
          <h3>企业用户广告系统，实现增值效果</h3>
          <p class="title-p">
            ENTERPRISE USER ADVERTISING SYSTEM TO ACHIEVE VALUE-ADDED EFFECT
          </p>
        </div>
        <div class="right">
          <img alt="" src="../assets/image/env1.png" />
        </div>
      </div>
    </div>
    <!-- 产品案例 -->
    <div class="case con content">
      <Title dsc="APPLICATION CASE" title="应用案例"></Title>
      <ul>
        <li>
          <img alt="" src="../assets/image/case3.png" />
          <div>乐山206(乐山人民医院高新院区外)</div>
        </li>
        <li>
          <img alt="" src="../assets/image/case2.png" />
          <div>乐山203(乐山高新实验小学)</div>
        </li>
        <li>
          <img alt="" src="../assets/image/case1.png" />
          <div>成都武侯区街心花园</div>
        </li>
      </ul>
      <router-link class="more" to="/system">了解更多</router-link>
    </div>
  </div>
</template>

<script>
  import Title from '@/components/Title.vue'
  import banner from '@/components/banner.vue'
  import Particles from '@/components/particles/index'
  // import Journalism from "@/components/journalism";
  // import footers from "@/components/Footer";

  export default {
    data() {
      return {
        bottom: '',
        theFirstRow: false, //第一排显示
        btnshow: false,
        deviceType: '',
      }
    },
    components: {
      Title,
      banner,
      particles: Particles,
      // journalism: Journalism,
      // footers,
    },
    watch: {
      $route: {
        // $route可以用引号，也可以不用引号  监听的对象
        handler(to) {
          // console.log("当前页面路由：" + to.path);
          // console.log('上一个路由：' + from);
          this.bottom = this.$route.query.bottom
          // console.log(this.$route.path)
          // console.log(this.bottom)
          if (to.path == '/' && this.bottom == 1) {
            this.$nextTick(() => {
              let clientHeight = document.body.scrollHeight
              // console.log(clientHeight);
              window.scrollTo(0, clientHeight)
            })
          }
        },
        deep: true, // 深度观察监听 设置为 true
        immediate: true, // 第一次初始化渲染就可以监听到,
        deviceType: '',
      },
    },
    mounted() {
      window.addEventListener('scroll', this.handlescroll)
      if (this._isMobile()) {
        console.log('手机端')
        this.deviceType = 'mobile'
      } else {
        console.log('PC端')
        this.deviceType = 'pc'
      }
    },

    methods: {
      //判断访问系统
      _isMobile() {
        let flag = navigator.userAgent.match(
          /(phone|pad|iPhone|ipod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
        )
        return flag
      },
      handlescroll() {
        //获取滚动高度
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop
        // console.log("滚动距离"+scrollTop)
        if (scrollTop > 3500) {
          this.theFirstRow = true
        }
        if (scrollTop > 5800) {
          this.btnshow = true
        }
      },
    },
  }
</script>
<style lang="less" scoped>
  .bannner {
    width: 100%;
    // height:800px;
    img {
      width: 100%;
    }
  }

  // 公司简介
  .introduction {
    width: 100%;
    height: 704px;
    background: url('../assets/image/introductions.png') no-repeat;
    background-size: 100% 100%;

    .titles {
      margin: 0 auto;
      width: 950px;
      display: flex;
      flex-direction: column;
      text-align: left;

      > h1 {
        display: inline-block;
        width: 490px;
        font-size: 32px;
        font-weight: bold;
        color: #00112b;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      }

      > p {
        padding-top: 20px;
        padding-left: 10px;
        display: inline-block;
        width: 420px;
        height: 30px;
        line-height: 30px;
        font-size: 24px;
        color: #333333;
      }

      > span {
        padding-top: 20px;
        font-size: 20px;
        color: #666666;
      }
    }

    a {
      margin-top: 62px;
      display: inline-block;
      width: 182px;
      height: 50px;
      line-height: 50px;
      border-radius: 25px;
      background: #2680eb;
      color: #ffffff;
      font-size: 20px;
    }
  }

  //5g智慧轻松驿站
  .public {
    width: 100%;
    height: 886px;
    position: relative;

    .bo {
      cursor: pointer;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  .text {
    height: 516px;
    text-align: left;
    background: #005bad;
    width: 50%;
    color: #fff;
    padding: 42px 40px;
    font-size: 28px;
    text-indent: 2em;
  }

  // 优势
  .advantage {
    margin-top: 110px;
    width: 100%;
    height: 860px;
    background: url('../assets/image/advantageImage.png') no-repeat;

    .newspaper {
      margin: 0 auto;
      width: 1100px;
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
      }

      .xia {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 60px;
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        font-size: 26px;
        line-height: 60px;
        z-index: 1;
      }
    }

    .list {
      width: 1100px;
      height: 506px;
      margin: 0 auto;

      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        li {
          background: #ffffff;
          box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.16);
          border-radius: 12px;
          width: 340px;
          height: 200px;
          opacity: 1;
          padding-top: 38px;

          div {
            font-size: 24px;
            color: #425672;
            margin: 20px 0;
          }

          p {
            color: #8394aa;
            font-size: 16px;
          }
        }
      }
    }
  }

  .mt-30 {
    margin-top: 30px;
  }

  .saas {
    background: url('../assets/image/advantage_bg.png') no-repeat;
    background-size: 100% 100%;
    padding: 60px 0;

    .hed {
      font-size: 40px;
      color: #fff;
    }

    .dsc {
      font-size: 18px;
      color: #fff;
      margin-bottom: 50px;
    }

    ul {
      width: 1100px;
      display: flex;
      justify-content: space-between;

      li {
        width: 260px;
        height: 350px;

        img {
          width: 100%;
          height: 100%;
        }

        &:hover {
          transform: scale(1.02);
          box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
          transition: 0.5s all 0s;
        }
      }
    }
  }

  // 环保
  .environmental {
    width: 100%;
    height: 850px;
    opacity: 1;
    background: url('../assets/image/Comp1_1.gif') no-repeat;
    background-size: 100% 100%;

    .contents {
      display: flex;
      flex-direction: column;
      width: 1100px;
      margin: 0 auto;

      .title {
        width: 100%;
      }

      .env-icon {
        display: flex;
        flex-wrap: wrap;
        width: 600px;
        height: 255px;
        margin: 0 auto;
        position: relative;

        .env_icon-1 {
          width: 200px;
          height: 85px;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          top: 45px;
          left: 414px;
          animation: myfirst 2s linear;

          img {
            width: 100px;
            height: 100px;
          }

          p {
            font-size: 24px;
            color: #333333;
          }
        }

        .env_icon-2 {
          width: 200px;
          height: 85px;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          top: 45px;
          left: -20px;
          animation: myfirst 2s linear;

          img {
            width: 100px;
            height: 100px;
          }

          p {
            color: #333333;
          }
        }

        .env_icon-3 {
          width: 200px;
          height: 85px;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          top: 240px;
          left: 558px;
          animation: myfirst 2s linear;

          img {
            width: 100px;
            height: 100px;
          }

          p {
            color: #333333;
          }
        }

        .env_icon-4 {
          width: 200px;
          height: 85px;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          top: 240px;
          left: -154px;
          animation: myfirst 2s linear;

          img {
            width: 100px;
            height: 100px;
          }

          p {
            color: #333333;
          }
        }

        .env_icon-5 {
          width: 200px;
          height: 85px;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          top: 458px;
          left: 568px;
          animation: myfirst 2s linear;

          img {
            width: 100px;
            height: 100px;
          }

          p {
            color: #333333;
          }
        }

        .env_icon-6 {
          width: 200px;
          height: 85px;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          top: 458px;
          left: -164px;
          animation: myfirst 2s linear;

          img {
            width: 100px;
            height: 100px;
          }

          p {
            color: #333333;
          }
        }
      }

      //大图
      .env-img {
        img {
          width: 450px;
          height: 331px;
        }
      }
    }

    //app

    @keyframes myfirst {
      0% {
        transform: scale(0.2);
      }
      100% {
        transform: scale(1);
      }
    }
    //信息
    .env_icon-2 {
      width: 322px;
      height: 85px;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 294px;
      left: 382px;
      animation: myfirst 5s;

      img {
        width: 130px;
        height: 130px;
      }

      p {
        font-size: 24px;
        color: #333333;
      }
    }

    //大数据
    .env_icon-3 {
      width: 322px;
      height: 85px;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 68px;
      left: 370px;
      animation: myfirst 5s;

      img {
        width: 130px;
        height: 130px;
      }

      p {
        font-size: 24px;
        color: #333333;
      }
    }

    //小程序
    .env_icon-4 {
      width: 322px;
      height: 85px;
      display: flex;
      align-items: center;
      position: absolute;
      top: 268px;
      right: 520px;
      animation: myfirst 5s;

      img {
        width: 130px;
        height: 130px;
      }

      p {
        font-size: 24px;
        color: #333333;
      }
    }

    //人脸识别
    .env_icon-5 {
      width: 370px;
      height: 85px;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 294px;
      right: 282px;
      animation: myfirst 5s;

      img {
        width: 130px;
        height: 130px;
      }

      p {
        font-size: 24px;
        color: #333333;
      }
    }

    //广告信息发布
    .env_icon-6 {
      width: 322px;
      height: 85px;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 68px;
      right: 342px;
      animation: myfirst 5s;

      img {
        width: 130px;
        height: 130px;
      }

      p {
        font-size: 24px;
        color: #333333;
      }
    }
  }

  .env_bot {
    //margin-top: 40px;
    padding: 60px 0 150px;
    background: url('../assets/image/env.png') no-repeat;
    background-size: 100% 100%;

    .left {
      color: #fff;
      width: 100%;
      margin: 0 auto;

      h3 {
        font-size: 40px;
      }

      div {
        font-size: 24px;
        margin-bottom: 20px;
      }

      p {
        display: inline-block;
        text-align: center;
        width: 100%;
        font-size: 20px;
        opacity: 0.8;
      }
    }

    .right {
      margin: 0 auto;
      width: 1100px;
      height: 450px;

      > img {
        margin-top: 80px;
        width: 100%;
        height: 100%;
      }
    }
  }

  //产品案例
  .case {
    width: 100%;
    height: 750px;
    background: url('../assets/image/show.png') no-repeat;
    background-size: 100% 100%;

    ul {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      width: 1100px;

      li {
        width: 350px;
        height: 360px;
        position: relative;
        // cursor: pointer;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }

        &:hover {
          transform: scale(1.02);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
          transition: 0.5s all 0s;

          // div {
          //   //   height:60px;
          //   // line-height: 60px;
          //   // opacity: 1;
          //   // transition: 0.5s all 0s;
          // }
        }

        div {
          position: absolute;
          // height:0px;
          // line-height: 0px;
          // opacity: 0;
          height: 60px;
          line-height: 60px;
          background: rgba(0, 0, 0, 0.4);
          color: #fff;
          font-size: 20px;
          width: 100%;
          bottom: 0;
          left: 0;
        }
      }
    }

    a {
      display: inline-block;
      margin: 0 auto;
      margin-top: 62px;
      width: 182px;
      height: 50px;
      line-height: 50px;
      border-radius: 25px;
      background: #2680eb;
      color: #ffffff;
      font-size: 20px;
      animation: myfirst 5s;
    }
  }

  .case_img {
    width: 100%;
    margin-top: 60px;

    img {
      width: 100%;
    }
  }

  .environmental-mobile {
    background: #ffffff;

    .contents {
      height: 1850px;
    }

    .env-icon {
      margin: 0 auto;
      margin-top: 40px;
      width: 700px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .env_icon-1 {
        width: 300px;
        height: 300px;

        img {
          width: 240px;
          height: 240px;
        }
      }
    }
  }
</style>
